<template>
  <v-container
    fluid
    class="bg-white">
    <v-row align="center">
      <v-col cols="auto">
        <h2>Store Information</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          icon
          small
          color="secondary"
          :loading="loading"
          @click="editItem()">
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="!loading"
      align="center">
      <v-col cols="2">
        Store ID
      </v-col>
      <v-col cols="10">
        {{ storeData.id }}
      </v-col>
      <v-col cols="2">
        Unique Code
      </v-col>
      <v-col cols="10">
        {{ storeData.uniqueCode }}
      </v-col>
      <v-col cols="2">
        Name
      </v-col>
      <v-col cols="10">
        {{ storeData.name }}
      </v-col>
      <v-col cols="2">
        Company Name
      </v-col>
      <v-col cols="10">
        {{ storeData.companyName }}
      </v-col>
      <v-col cols="2">
        Tel.
      </v-col>
      <v-col cols="10">
        {{ storeData.tel }}
      </v-col>
      <v-col cols="2">
        Address
      </v-col>
      <v-col cols="10">
        {{ storeData.address }}
      </v-col>
      <v-col cols="2">
        Sub-District
      </v-col>
      <v-col cols="10">
        {{ storeData.subDistrict }}
      </v-col>
      <v-col cols="2">
        District
      </v-col>
      <v-col cols="10">
        {{ storeData.district }}
      </v-col>
      <v-col cols="2">
        State Province
      </v-col>
      <v-col cols="10">
        {{ storeData.stateProvince }}
      </v-col>
      <v-col cols="2">
        Postcode
      </v-col>
      <v-col cols="10">
        {{ storeData.postcode }}
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500px">
      <v-card>
        <validation-observer
          ref="storeForm"
          tag="form"
          @submit.prevent="save()">
          <v-card-title>
            <span class="headline">Edit Store</span>
          </v-card-title>
          <v-card-text>
            <v-container v-if="dialog">
              <v-row>
                <v-col cols="12">
                  <gw-text-field
                    v-model="formData.companyName"
                    :readonly="modalLoading"
                    label="Company Name" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <gw-text-field
                    v-model="formData.uniqueCode"
                    :readonly="modalLoading"
                    label="Unique Code" />
                </v-col>
                <v-col cols="6">
                  <gw-text-field
                    v-model="formData.tel"
                    :readonly="modalLoading"
                    label="Telephone" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <gw-text-field
                    v-model="formData.address"
                    :readonly="modalLoading"
                    label="Address" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <gw-text-field
                    v-model="formData.district"
                    :readonly="modalLoading"
                    label="District" />
                </v-col>
                <v-col cols="6">
                  <gw-text-field
                    v-model="formData.subDistrict"
                    :readonly="modalLoading"
                    label="Sub-District" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <gw-text-field
                    v-model="formData.stateProvince"
                    :readonly="modalLoading"
                    label="State Province" />
                </v-col>
                <v-col cols="6">
                  <gw-text-field
                    v-model="formData.postcode"
                    :readonly="modalLoading"
                    label="Postcode" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeFormModal()">
              Cancel
            </v-btn>
            <v-btn
              :loading="modalLoading"
              color="blue darken-1"
              type="submit"
              text>
              Save
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import StoreProvider from '@/resources/StoreProvider'

const StoreService = new StoreProvider()

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      storeData: null,
      formData: {
        id: null,
        storeId: null,
        companyName: '',
        uniqueCode: '',
        status: '',
        name: '',
        tel: '',
        address: '',
        subDistrict: '',
        district: '',
        stateProvince: '',
        postcode: ''
      },
      modalLoading: false
    }
  },
  created () {
    this.getStore()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getStore () {
      try {
        this.loading = true
        const { data } = await StoreService.getStoreByToken()
        this.storeData = data
      } catch (error) {
        console.error('getStore: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    closeFormModal () {
      this.dialog = false
      this.formData = {
        id: null,
        storeId: null,
        companyName: '',
        uniqueCode: '',
        status: '',
        name: '',
        tel: '',
        address: '',
        subDistrict: '',
        district: '',
        stateProvince: '',
        postcode: ''
      }
    },
    editItem () {
      this.formData = {
        id: this.storeData.id,
        storeId: this.storeData.storeId,
        companyName: this.storeData.companyName,
        uniqueCode: this.storeData.uniqueCode,
        status: this.storeData.status,
        name: this.storeData.name,
        tel: this.storeData.tel,
        address: this.storeData.address,
        subDistrict: this.storeData.subDistrict,
        district: this.storeData.district,
        stateProvince: this.storeData.stateProvince,
        postcode: this.storeData.postcode
      }
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const valid = await this.$refs.storeForm.validate()
      if (valid) {
        try {
          await StoreService.updateStore(this.formData)

          this.setSnackbar({
            value: true,
            message: 'Update successfully.',
            type: 'success'
          })
          this.getStore()
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
          console.error('saveStore', error)
        } finally {
          this.closeFormModal()
        }
      }
      this.modalLoading = false
    }
  }
}
</script>
